<template>
  <div class="detail">
    <div class="header">
      <div class="title">
        {{ status === "add" ? "新增排水户" : "修改排水户" }}
      </div>
    </div>

    <div class="body">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form" @submit="handleSubmit">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排水户名称">
              <a-input v-decorator="[
                'name',
                {
                  initialValue: detail.name,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-select style="width: 100%" v-decorator="[
                'firmType',
                {
                  initialValue: detail.firmType ? detail.firmType : 'notgiven',
                  rules: [{ required: true, message: '请选择' }],
                },
              ]">
                <a-select-option v-for="item in typeList" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="法人">
              <a-input v-decorator="[
                'legalPerson',
                {
                  initialValue: detail.legalPerson,
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系人">
              <a-input v-decorator="[
                'contanctPerson',
                {
                  initialValue: detail.contanctPerson,
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="营业面积(㎡)">
              <a-input-number style="width: 100%" v-decorator="[
                'businessArea',
                {
                  initialValue: detail.businessArea,
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="位置">
              <a-input v-decorator="[
                'location',
                {
                  initialValue: detail.location,
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经度">
              <a-input-number style="width: 100%" placeholder="请点击地图" v-decorator="[
                'x',
                {
                  initialValue: detail.x,
                  rules: [{ required: true, message: '请点击地图' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="纬度">
              <a-input-number style="width: 100%" placeholder="请点击地图" v-decorator="[
                'y',
                {
                  initialValue: detail.y,
                  rules: [{ required: true, message: '请点击地图' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排水户类型">
              <a-radio-group style="width: 100%" v-decorator="[
                'dischargerType',
                {
                  initialValue: detail.dischargerType,
                },
              ]">
                <a-radio v-for="item in dischargerType" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排水量">
              <a-radio-group style="width: 100%" v-decorator="[
                'quantity',
                {
                  initialValue: detail.quantity,
                },
              ]">
                <a-radio v-for="item in [
                  {
                    value: '大',
                    name: '大',
                  },
                  {
                    value: '中',
                    name: '中',
                  },
                  {
                    value: '小',
                    name: '小',
                  },
                ]" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排放污染物浓度">
              <a-radio-group style="width: 100%" v-decorator="[
                'concentration',
                {
                  initialValue: detail.concentration,
                },
              ]">
                <a-radio v-for="item in concentration" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="是否重点排水户">
              <a-radio-group style="width: 100%" v-decorator="[
                'isKeyDischarger',
                {
                  initialValue: detail.isKeyDischarger,
                },
              ]">
                <a-radio v-for="item in [
                  {
                    name: '是',
                    value: true,
                  },
                  {
                    name: '否',
                    value: false,
                  },
                ]" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="是否许可">
              <a-radio-group style="width: 100%" v-decorator="[
                'isLicensed',
                {
                  initialValue: detail.isLicensed,
                },
              ]">
                <a-radio v-for="item in [
                  {
                    name: '是',
                    value: true,
                  },
                  {
                    name: '否',
                    value: false,
                  },
                ]" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="loading">保存</a-button>
            <a-button v-if="status === 'edit'" type="danger" @click="remove">删除</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import request from "@/api/request";

function addRequest(data) {
  return request({
    url: "/model-analysis/discharger/save",
    method: "post",
    data,
  });
}

function editRequest(data) {
  return request({
    url: "/model-analysis/discharger/modify",
    method: "post",
    data,
  });
}

function removeRequest(data) {
  return request({
    url: "/model-analysis/discharger/delete/" + data.id,
    method: "post",
  });
}

export default {
  props: {
    status: {
      type: String,
    },
    selectedLnglat: {
      type: Object,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    dict() {
      return this.findDataDict("discharger");
    },
    typeList() {
      const res = this.dict.find((item) => item.value === "firmType");
      if (res && Array.isArray(res.children)) {
        return res.children;
      } else {
        return [];
      }
    },
    dischargerType() {
      const res = this.dict.find((item) => item.value === "dischargerType");
      if (res && Array.isArray(res.children)) {
        return res.children;
      } else {
        return [];
      }
    },
    concentration() {
      const res = this.dict.find((item) => item.value === "concentration");
      if (res && Array.isArray(res.children)) {
        return res.children;
      } else {
        return [];
      }
    },
  },

  watch: {
    selectedLnglat() {
      if (this.selectedLnglat.lng) {
        this.form.setFieldsValue({
          x: this.selectedLnglat.lng,
          y: this.selectedLnglat.lat,
        });
      }
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          this.loading = true;

          if (this.status === "add") {
            addRequest({
              ...values,
            })
              .then(async () => {
                this.cancel();
                this.$emit("refresh");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            editRequest({
              id: this.detail.id,
              ...values,
            })
              .then(async () => {
                this.cancel();
                this.$emit("refresh");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },

    cancel() {
      this.$emit("cancel");
      this.form.resetFields();
    },

    remove() {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          removeRequest({
            id: that.detail.id,
          }).then(() => {
            that.cancel();
            that.$emit("refresh");
          });
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  height: 87vh;
  overflow-y: auto;
  right: 1vw;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .body {
    margin-top: 4vh;

    .center {
      margin-top: 4vh;
    }
  }
}
</style>