<template>
  <div class="list">
    <!---->
    <div>排水户列表</div>
    <div class="item" v-for="item in list " :key="item.id">
      <div class="header">
        <div class="title">{{ item.name }}</div>
      </div>
      <div class="info">
        <span>{{ item.isLicensed ? '排水证：有' : '排水证：无' }}</span>
        <span style="margin-left: auto;cursor: pointer" @click="$emit('focus', item)">
          <img src="@/assets/topic/location.png" style="width: 18px" alt />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  width: 100%;
  height: 600px;
  overflow: scroll;
  flex-direction: column;
  gap: 1em;
  margin: 1em;
  padding: 1em;

  .item {
    border: 1px solid #f0f0f0;

    padding: 1em;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: bold;
        font-size: 1.1em;
      }

      .extra {
        color: #999;
        font-size: 0.9em;
      }
    }

    .info {
      margin-top: 0.5em;
      color: #999;
      display: flex;
      align-items: center;
    }
  }
}
</style>