var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.status === "add" ? "新增排水户" : "修改排水户")+" ")])]),_c('div',{staticClass:"body"},[_c('a-form',{attrs:{"label-col":{ span: 8 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排水户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.detail.name,
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'name',\n              {\n                initialValue: detail.name,\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'firmType',
              {
                initialValue: _vm.detail.firmType ? _vm.detail.firmType : 'notgiven',
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'firmType',\n              {\n                initialValue: detail.firmType ? detail.firmType : 'notgiven',\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"法人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'legalPerson',
              {
                initialValue: _vm.detail.legalPerson,
              },
            ]),expression:"[\n              'legalPerson',\n              {\n                initialValue: detail.legalPerson,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'contanctPerson',
              {
                initialValue: _vm.detail.contanctPerson,
              },
            ]),expression:"[\n              'contanctPerson',\n              {\n                initialValue: detail.contanctPerson,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"营业面积(㎡)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'businessArea',
              {
                initialValue: _vm.detail.businessArea,
              },
            ]),expression:"[\n              'businessArea',\n              {\n                initialValue: detail.businessArea,\n              },\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"位置"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'location',
              {
                initialValue: _vm.detail.location,
              },
            ]),expression:"[\n              'location',\n              {\n                initialValue: detail.location,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"经度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'x',
              {
                initialValue: _vm.detail.x,
                rules: [{ required: true, message: '请点击地图' }],
              },
            ]),expression:"[\n              'x',\n              {\n                initialValue: detail.x,\n                rules: [{ required: true, message: '请点击地图' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请点击地图"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"纬度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'y',
              {
                initialValue: _vm.detail.y,
                rules: [{ required: true, message: '请点击地图' }],
              },
            ]),expression:"[\n              'y',\n              {\n                initialValue: detail.y,\n                rules: [{ required: true, message: '请点击地图' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请点击地图"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排水户类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'dischargerType',
              {
                initialValue: _vm.detail.dischargerType,
              },
            ]),expression:"[\n              'dischargerType',\n              {\n                initialValue: detail.dischargerType,\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.dischargerType),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排水量"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'quantity',
              {
                initialValue: _vm.detail.quantity,
              },
            ]),expression:"[\n              'quantity',\n              {\n                initialValue: detail.quantity,\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l(([
                {
                  value: '大',
                  name: '大',
                },
                {
                  value: '中',
                  name: '中',
                },
                {
                  value: '小',
                  name: '小',
                },
              ]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排放污染物浓度"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'concentration',
              {
                initialValue: _vm.detail.concentration,
              },
            ]),expression:"[\n              'concentration',\n              {\n                initialValue: detail.concentration,\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.concentration),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否重点排水户"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'isKeyDischarger',
              {
                initialValue: _vm.detail.isKeyDischarger,
              },
            ]),expression:"[\n              'isKeyDischarger',\n              {\n                initialValue: detail.isKeyDischarger,\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l(([
                {
                  name: '是',
                  value: true,
                },
                {
                  name: '否',
                  value: false,
                },
              ]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否许可"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'isLicensed',
              {
                initialValue: _vm.detail.isLicensed,
              },
            ]),expression:"[\n              'isLicensed',\n              {\n                initialValue: detail.isLicensed,\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l(([
                {
                  name: '是',
                  value: true,
                },
                {
                  name: '否',
                  value: false,
                },
              ]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")]),(_vm.status === 'edit')?_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.remove}},[_vm._v("删除")]):_vm._e()],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }