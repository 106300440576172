<template>
  <div>
    <!--显示排水户地图空间-->
    <div id="map"></div>

    <!--排水户视图左边菜单-->
    <div class="menu ">

      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.name" style="width: 180px" placeholder="排水户名称" />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
            <a-button type="primary" @click="add" :disabled="!permissionAdd">新增</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <ItemList :list="list" @focus="setActive"></ItemList>
    </div>

    <Detail v-if="visible" :status="status" :selectedLnglat="selectedLnglat" :detail="aimDetail" @cancel="cancel"
      @refresh="getList" />
  </div>
</template>
<script>
import Detail from "./components/detail.vue";
import request from "@/api/request";
import { mapGetters } from "vuex";
import ItemList from "./components/list.vue"
export default {
  components: {
    Detail,
    ItemList
  },

  data() {
    return {
      form: {},
      list: [],

      visible: false,
      status: "",

      selectedLnglat: {},

      aimDetail: {},

    };
  },

  mounted() {
    this.initMap();

    this.getList();
  },

  beforeDestroy() {
    this.markers?.forEach((item) => {
      item.off("click", this.onMarkerClick);
    });



    this.map?.off("click", this.onMarkerClick);

    this.map?.clearMap();
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 16,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      this.map = map;
      this.map.on("click", this.onMapClick);
    },

    onMapClick(e) {
      this.selectedLnglat = {
        lng: e.lnglat.getLng(),
        lat: e.lnglat.getLat(),
      };
    },

    getList() {
      const hide = this.$message.loading("加载中...", 0);
      request({
        url: '/model-analysis/discharger/list',
        method: "post",
        data: {
          pageNum: 1,
          pageSize: 999,
          ...this.form,
        },
      })
        .then((res) => {
          if (res && Array.isArray(res.list) && res.list.length > 0) {
            this.list = Object.freeze(res.list);
            this.addMarker();
          } else {
            this.list = [];
            this.addMarker();
          }
        })
        .finally(() => {
          hide();
        });
    },

    query() {
      this.getList();
    },

    reset() {
      this.form = {};
      this.getList();
    },

    addMarker() {
      if (Array.isArray(this.markers)) {
        this.markers.forEach((item) => {
          item.off("click", this.onMarkerClick);
          this.map?.remove(item);
        });
      }

      const markers = this.list.map((item) => {
        const content = `<div>
                <div style='background-color: ${!item.expired ? '#01C8E3' : 'red'};color: #fff;border-radius: 4px;padding: 4px 8px;text-align:center;min-width: 120px'>${item.name}</div>
              </div>
            `;

        const marker = new window.AMap.Marker({
          position: [item.x, item.y],
          content,
          extData: item,
        });

        marker.on("click", this.onMarkerClick);
        return marker;
      });

      this.map.add(markers);
      this.markers = markers;
    },

    onMarkerClick(e) {
      console.log(e);
      this.cancel();
      this.aimDetail = e.target._opts.extData;
      this.status = "edit";
      this.visible = true;
    },

    add() {
      this.visible = true;
      this.status = "add";
    },
    cancel() {
      this.visible = false;
      this.status = "";
      this.selectedLnglat = {};
      this.aimDetail = {};
    },

    parseDeviceLocation(location) {
      try {
        const result = JSON.parse(location)
        console.log(result)
        return result
      } catch (err) {
        return null
      }
    },
    setActive(item) {
      console.log("item,", item)
      // 切换视角
      this.map?.setCenter([item.x, item.y], {
      });
    }
  },
  computed: {
    //增加权限显示控制
    ...mapGetters("auth", ["hasPermission"]),
    permissionAdd() {
      return this.hasPermission("model-analysis:discharger:add")
    },
    permissionEdit() {
      return this.hasPermission("model-analysis:discharger:modify")
    }
  }
};
</script>


<style lang="less" scoped>
#map {
  position: fixed;
  left: 4vw;
  right: 4vw;
  bottom: 4vh;
  top: 10vh;
}

.menu {
  position: fixed;
  top: 10vh;
  left: 4vw;
  background-color: #000;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>